<template xmlns="http://www.w3.org/1999/html">
  <div class="scan-container">
    <div class="stream-b-r-container" >
      <StreamBarcodeReader
        v-if="isShowStreamScan"
        ref="StreamBarcodeReaderRef"
        @decode="onStreamDecode"
        @loaded="onStreamLoaded"
      ></StreamBarcodeReader>
    </div>
<!--    可以使用手机摄像头扫码，本组件目前用不到了 -->
    <div class="barcode-container" v-if="false">
      <ImageBarcodeReader
        @decode="onImageDecode"
        @error="onImageError"
        ref="ImageBarcodeReaderRef"
        v-show="false"
      ></ImageBarcodeReader>
      <a-button @click="startScan">SCAN</a-button>
    </div>
    <div class="item-no-container">
      <a-form :form="form" :label-col="{ span: 5 }" :wrapper-col="{ span: 12 }" hideRequiredMark>
        <a-form-item label="Enter Item No">
          <a-input
            ref="itemNoRef"
            autoFocus
            autocomplete="off"
            @keypress.enter="enterItemNo"
            v-decorator="['itemNo', { rules: [{ required: true, message: 'Please Enter Item No' }] }]" />
        </a-form-item>
      </a-form>
      <a-icon @click="initItemNo" class="item-no-icon-close" type="close" />
    </div>
    <div class="btn-container">
      <a-button @click="next">Next</a-button>
      <a-button @click="submit">PREVIEW</a-button>
    </div>
    <audio controls v-show="false" ref="audioRef">
      <source src="../assets/di.wav">
    </audio>
  </div>
</template>

<script>
import {ImageBarcodeReader, StreamBarcodeReader} from "vue-barcode-reader";
export default {
  name: "scan",
  components: {
    ImageBarcodeReader,
    StreamBarcodeReader
  },
  data() {
    return {
      form: this.$form.createForm(this, { name: 'itemNo' }),
      isShowStreamScan: true,
      timer: null,
    };
  },
  created() {
  },
  mounted() {
    const itemNo = localStorage.getItem('itemNo')
    itemNo && this.setItemNo(itemNo)
  },
  methods: {
    // 识别失败
    onImageError() {
      this.$message.error('scan failed');
    },
    // 识别成功
    onImageDecode(res) {
      const oldItemNo = this.form.getFieldsValue().itemNo
      if (oldItemNo === res) {
        return console.log('ItemNo 重复')
      }
      this.$message.success('scan successful');
      this.setItemNo(res)
    },
    submit() {
      const orderInfo = JSON.parse(localStorage.getItem('orderInfo'))
      if (!orderInfo || !Array.isArray(orderInfo) || orderInfo.length < 1) {
        return this.$message.warning('Please add item first');
      }
      this.$router.replace('/order-info')
    },
    next() {
      this.form.validateFields(async (err, values) => {
        const {itemNo} = values
        if (err || !itemNo) {
          return
        }
        let isNext = true
        const orderInfo = JSON.parse(localStorage.getItem('orderInfo'))
        if (orderInfo && Array.isArray(orderInfo) && orderInfo.find(item => item.itemNo === itemNo)) {
          isNext = await this.confirmRepeat('The item already exists. Do you want to continue ?')
        }
        if (!isNext) {
          return this.initItemNo()
        }
        this.toItemNumPage(itemNo)
      });
    },
    setItemNo(itemNo) {
      this.form.setFieldsValue({itemNo});
    },
    startScan() {
      const el = this.$refs.ImageBarcodeReaderRef.$el
      const e = document.createEvent("MouseEvents");
      e.initEvent("click", true, true);
      el.dispatchEvent(e);
    },
    // 摄像头识别成功
    onStreamDecode(res){
      if (this.timer) {
        return
      }
      this.timer = true
      setTimeout(() => {
        this.timer = null
      }, 1500)
      if (res && typeof res === 'string') {
        return this.scanSuccessful(res)
      }
      const str = JSON.stringify(res)
      if (str && typeof str === 'string') {
        return this.scanSuccessful(str)
      }
    },
    onStreamLoaded(){
      console.log('StreamBarcodeReader onStreamLoaded');
    },
    toItemNumPage(itemNo) {
      localStorage.setItem('itemNo', itemNo)
      this.$router.push('/item-num')
    },
    confirmRepeat(content) {
      return new Promise(resolve => {
        this.$confirm({
          content: content,
          onOk: () => {
            resolve(true)
          },
          onCancel: () => {
            resolve(false)
          }
        })
      })
    },
    startStreamScan() {
      this.isShowStreamScan = true
    },
    stopStreamScan() {
      this.isShowStreamScan = false
    },
    playAudio() {
      this.$refs.audioRef.play()
    },
    scanSuccessful(res) {
      if (typeof res !== 'string') {
        res = res.toString()
      }
      // upc-12会在前面补一个0，如果是13位，并且是0开头时，去掉开头的0
      if (res.length === 13 && res.indexOf('0') === 0) {
        res = res.substr(1)
      }
      this.playAudio()
      setTimeout(() => {
        window.navigator.vibrate(200)
      }, 500)
      // 声音时长为1s
      setTimeout(() => {
        // this.stopStreamScan()
        this.$message.success('scan successful');
        return this.setItemNo(res)
      }, 700)
    },
    initItemNo() {
      this.setItemNo('')
      this.$refs.itemNoRef.focus()
    },
    enterItemNo() {
      this.$refs.itemNoRef.select()
    }
  }
}
</script>

<style lang="scss">
.scan-container {
  padding: 0 10px 10px;
  overflow: hidden;

  .btn-container {
    display: flex;
    justify-content: space-between;
  }

  .item-no-container {
    display: flex;
    align-items: flex-end;

    .ant-form {
      flex: 1;
      .ant-form-item {
        margin-bottom: 5px;
      }
    }

    .item-no-icon-close {
      font-size: 30px;
      margin-bottom: 3px;
      margin-left: 5px;
      line-height: 40px;
      color: red;
    }
  }

  .stream-b-r-container {
    overflow: hidden;
    margin: 10px auto;
    max-height: 300px;
    min-height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;

    .ant-btn {
      min-width: 70%;
      height: 100px;
    }

    .scanner-container {
      max-height: 180px;

      > div {
        position: relative;
        top: 90px;
        transform: translateY(-50%);
      }
    }
  }

  .barcode-container {
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;

    .ant-btn {
      min-width: 70%;
      min-height: 80%;
    }
  }
}
</style>
